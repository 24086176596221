<template>
  <app-layout>
    <template v-slot:navigationLeftDrawer>
      <agenda-drawer></agenda-drawer>
    </template>
  </app-layout>
</template>

<script>
import { baseHomeMixin } from "@/mixins/shared/base/home/baseHomeMixin";
export default {
  name: "AgendaHome",
  mixins: [baseHomeMixin],
  components: {
    AgendaDrawer: () => import("@/views/agenda/AgendaDrawer")
  },
  computed: {
    selectApplicationName() {
      return this.applicationNames.Agenda;
    }
  }
};
</script>
